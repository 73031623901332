import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore"
import { NewsletterEntity } from "../entities/NewsletterEntity"
import { INewsletterRepository } from "../interfaces/INewsletterRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseNewsletterRepository
  extends FirebaseUtils
  implements INewsletterRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "newsletter"

  async get(params: { userId: string }): Promise<NewsletterEntity | null> {
    const db = this.firebase.database()

    const c = query(
      collection(db, this.collection),
      where("userId", "==", params.userId)
    )

    const response = await getDocs(c)

    if (!response.empty) return null

    const data = this.mapQuerySnapshot<NewsletterEntity>(response.docs)[0]

    return data
  }

  async update(params: {
    userId: string
    newsletter: NewsletterEntity
  }): Promise<NewsletterEntity> {
    const db = this.firebase.database()

    await setDoc(doc(db, this.collection, params.userId), params.newsletter)

    return params.newsletter
  }
}
