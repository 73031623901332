import React from "react"

export const H1 = ({ children, id }) => (
  <h1
    id={id}
    className="max-w-2xl font-display mx-auto mt-12 text-4xl font-bold"
  >
    {children}
  </h1>
)

export const H2 = (props) => (
  <h2
    id={props.id}
    className={`max-w-3xl mx-auto py-2 font-display pt-10 text-3xl font-bold tracking-tight text-slate-900`}
  >
    {props.children}
  </h2>
)

export const H3 = ({ children, id }) => (
  <h3
    id={id}
    className="max-w-3xl mx-auto font-display pt-10 py-2 text-3xl font-bold text-slate-900"
  >
    {children}
  </h3>
)

export const H4 = ({ children, id }) => (
  <h4
    id={id}
    className="max-w-3xl mx-auto font-display py-2 pt-10 text-3xl font-bold text-slate-900"
  >
    {children}
  </h4>
)

export const H5 = ({ children, id }) => (
  <h5
    id={id}
    className="max-w-3xl mx-auto font-display py-2 pt-10 text-2xl font-bold text-slate-900"
  >
    {children}
  </h5>
)

export const H6 = ({ children, id }) => (
  <h6
    id={id}
    className="max-w-3xl mx-auto font-display py-2 pt-10 text-xl font-bold text-slate-900"
  >
    {children}
  </h6>
)
