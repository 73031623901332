import { navigate } from "@reach/router"

export const openDrawer = () => {}

export const closeDrawer = () => {
  const pathname = window.location.pathname
  const hash = window.location.hash
  if (hash.includes("menu=open")) navigate(pathname)
}

export const SUBSCRIPTION_MODAL_HASH = "premium-modal=open"

export const openSubscriptionModal = () => {
  const pathname = window.location.pathname

  navigate(pathname + "#" + SUBSCRIPTION_MODAL_HASH)
}

export const closeSubscriptionModal = () => {
  const pathname = window.location.pathname
  const hash = window.location.hash
  if (hash.includes(SUBSCRIPTION_MODAL_HASH)) navigate(pathname)
}

export const SUBSCRIPTION_MODAL_SUCCESS_HASH = "premium-modal-success=open"
export const openSubscriptionSuccessModal = () => {
  const pathname = window.location.pathname

  navigate(pathname + "#" + SUBSCRIPTION_MODAL_SUCCESS_HASH)
}

export const closeSubscriptionSuccessModal = () => {
  const pathname = window.location.pathname
  const hash = window.location.hash
  if (hash.includes(SUBSCRIPTION_MODAL_SUCCESS_HASH)) navigate(pathname)
}
