import dayjs from "dayjs"
import { StatEntity } from "../entities/StatEntity"
import {
  IStatsRepository,
  IStatsRepositoryPeriod,
} from "../interfaces/IStatsRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore"

export class FirebaseStatsRepository
  extends FirebaseUtils
  implements IStatsRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "stats"

  async findAll(params: {
    period: IStatsRepositoryPeriod
    userId: string
  }): Promise<StatEntity[]> {
    const today = dayjs()
    const endDigit =
      params.period === "week" ? 7 : params.period === "month" ? 30 : 12
    const endUnit = params.period === "year" ? "month" : "day"

    const end = dayjs().subtract(endDigit, endUnit)

    return this.getAll({
      userId: params.userId,
      interval: {
        end: today.toDate(),
        start: end.toDate(),
      },
    })
  }

  async findToday(params: { userId: string }): Promise<StatEntity[]> {
    const db = this.firebase.database()
    const c = query(
      collection(db, "users", params.userId, this.collection),
      where("date", ">=", dayjs().startOf("day").toDate()),
      limit(1)
    )

    const response = await getDocs(c)

    if (response.empty) return []

    return this.mapQuerySnapshot<StatEntity>(response.docs).map((stat) => ({
      ...stat,
      // @ts-ignore
      date: stat.date.toDate(),
    }))
  }

  private async getAll(params: {
    userId: string
    interval?: {
      start: Date
      end: Date
    }
  }): Promise<StatEntity[]> {
    const firestore = this.firebase.database()
    const c = query(
      collection(firestore, "users", params.userId, this.collection),
      ...(params.interval
        ? [
            where("date", ">=", params.interval.start),
            where("date", "<=", params.interval.end),
          ]
        : [])
    )

    const response = await getDocs(c)

    if (response.empty) return []

    return this.mapQuerySnapshot<StatEntity>(response.docs).map((stat) => ({
      ...stat,
      // @ts-ignore
      date: stat.date.toDate() as Date,
    }))
  }

  async store(params: {
    stats: StatEntity
    userId: string
  }): Promise<StatEntity> {
    const db = this.firebase.database()
    const c = collection(db, "users", params.userId, this.collection)

    const id = doc(c).id

    await setDoc(doc(c, id), { ...params.stats, id }, { merge: true })

    return params.stats
  }

  async sync(params: { userId: string }): Promise<{ succeed: boolean }> {
    try {
      const stats: StatEntity[] = JSON.parse(
        window.localStorage.getItem("stats") || "[]"
      )

      if (stats.length === 0) return { succeed: true }

      for (const stat of stats) {
        await this.store({
          stats: {
            ...stat,
            date: new Date(stat.date),
          },
          userId: params.userId,
        })
      }

      window.localStorage.removeItem("stats")

      return { succeed: true }
    } catch (e) {
      return { succeed: false }
    }
  }
}
