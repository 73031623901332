import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import {
  MarketplaceBougthItemEntity,
  MarketplaceEntity,
} from "../entities/MarketplaceEntity"
import { IMarketplaceRepository } from "../interfaces/IMarketplaceRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseMarketplaceRepository
  extends FirebaseUtils
  implements IMarketplaceRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "marketplace"

  async getAll(params: {
    userId: string
  }): Promise<MarketplaceBougthItemEntity[]> {
    const db = this.firebase.database()
    const c = query(
      collection(db, this.collection),
      where("userId", "==", params.userId)
    )

    const response = await getDocs(c)

    if (response.empty) return []

    return this.mapQuerySnapshot<MarketplaceBougthItemEntity>(
      response.docs
    ).map((item) => ({
      ...item,
      // @ts-ignore
      bougthAt: item.bougthAt.toDate(),
    }))
  }

  async getOne(params: {
    userId: string
    itemId: string
  }): Promise<MarketplaceBougthItemEntity | null> {
    const db = this.firebase.database()
    const c = query(
      collection(db, this.collection),
      where("userId", "==", params.userId),
      where("itemId", "==", params.itemId)
    )

    const response = await getDocs(c)

    if (response.empty) return null

    return (
      this.mapQuerySnapshot<MarketplaceBougthItemEntity>(response.docs).map(
        (item) => ({
          ...item,
          // @ts-ignore
          bougthAt: item.bougthAt.toDate(),
        })
      )[0] || null
    )
  }

  async store(params: {
    userId: string
    item: MarketplaceBougthItemEntity
  }): Promise<MarketplaceBougthItemEntity> {
    const db = this.firebase.database()
    const c = query(
      collection(db, this.collection),
      where("userId", "==", params.userId),
      where("itemId", "==", params.item.itemId),
      limit(1)
    )

    const response = await getDocs(c)

    if (!response.empty) {
      const item = this.mapQuerySnapshot<MarketplaceBougthItemEntity>(
        response.docs
      )[0]

      await updateDoc(doc(db, this.collection, item.id), {
        ...item,
        ...params.item,
      })

      return { ...item, ...params.item }
    }

    const document = collection(db, this.collection)

    const entity = { ...params.item, id: document.id }

    await setDoc(doc(db, this.collection, entity.id), entity, { merge: true })

    return entity
  }
}
