import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { GoogleAuthProvider, getAuth } from "firebase/auth"

import config from "../../firebase/client.json"

export class Firebase {
  private app

  constructor() {
    this.app = initializeApp(config)
  }

  database() {
    return getFirestore(this.app)
  }

  getInstance() {
    return this.app
  }

  getGoogleProvider() {
    return new GoogleAuthProvider()
  }

  auth() {
    return getAuth(this.app)
  }
}
