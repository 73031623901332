import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore"
import { ChestEntity } from "../entities/ChestEntity"
import { IChestRepository } from "../interfaces/IChestRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseChestRepository
  extends FirebaseUtils
  implements IChestRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "chests"

  async getAll(params: { userId: string }): Promise<ChestEntity[]> {
    const db = this.firebase.database()
    const chestCollection = query(
      collection(db, this.collection),
      where("userId", "==", params.userId)
    )

    const response = await getDocs(chestCollection)

    if (response.empty) return []

    return this.mapQuerySnapshot<ChestEntity>(response.docs).map((chest) => ({
      ...chest,
    }))
  }

  async remove(params: { userId: string; chestId: string }): Promise<void> {
    const db = this.firebase.database()

    await deleteDoc(doc(db, this.collection, params.chestId))
  }

  async create(params: { chest: ChestEntity }): Promise<ChestEntity> {
    const db = this.firebase.database()

    const docRef = doc(collection(db, this.collection))

    const entity = {
      ...params.chest,
      id: docRef.id,
    }

    await setDoc(docRef, entity, { merge: true })

    return entity
  }
}
