import { combineReducers } from "redux"
import { actions } from "./actions"
import { configureStore } from "@reduxjs/toolkit"
import { playReducer } from "./play/reducers"
import { diReducer } from "./di/reducers"
import { DiInjectable } from "./di/types"
import { darkModeReducer } from "./dark-mode/reducers"
import { statsReducer } from "./stats/reducers"
import { achievementsReducer } from "./achievements/reducers"
import { pointsReducer } from "./points/reducers"
import { langReducer } from "./lang/reducers"
import { authReducer } from "./auth/reducers"
import { notificationsReducer } from "./notifications/reducers"
import { dailyReducer } from "./daily/reducers"
import { printReducer } from "./print/reducers"
import { coinsReducer } from "./coins/reducers"
import { globalReducer } from "./global/reducers"
import { chestsReducer } from "./chests/reducers"
import { marketplaceReducer } from "./marketplace/reducers"
import { pricingReducer } from "./pricing/reducers"
import { newsletterReducer } from "./newsletter/reducers"

export const reducers = combineReducers({
  play: playReducer,
  di: diReducer,
  print: printReducer,
  newsletter: newsletterReducer,
  achievements: achievementsReducer,
  notifcations: notificationsReducer,
  stats: statsReducer,
  points: pointsReducer,
  auth: authReducer,
  lang: langReducer,
  darkMode: darkModeReducer,
  daily: dailyReducer,
  global: globalReducer,
  coins: coinsReducer,
  chests: chestsReducer,
  marketplace: marketplaceReducer,
  pricing: pricingReducer,
})

export type RootState = ReturnType<typeof reducers>

export const init = (initialState = {}, di: DiInjectable) => {
  const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
  })

  store.dispatch(actions.di.register(di))

  return { store }
}
