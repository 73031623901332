import React, { Fragment, useState } from "react"
import { Transition } from "@headlessui/react"
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon"
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon"
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon"
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon"
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon"
import { connector, ContainerProps } from "./containers/Notifications.container"
import { NotificationEntity } from "../../entities/NotificationEntity"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { ITranslations } from "../../interfaces/ITranslations"

const getMessage = (
  message: NotificationEntity["message"]
): ITranslations["keys"] => {
  if (message === "logout") return "notifications.logout.description"

  if (message === "login") return "notifications.login.description"

  if (message === "avatar/update/empty")
    return "notifications/avatar/update/empty"

  if (message === "avatar/update/not-exists")
    return "notifications/avatar/update/not-exists"

  if (message === "avatar/update/not-logged")
    return "notifications/avatar/update/not-logged"

  if (message === "avatar/updated") return "notifications/avatar/updated"

  if (message === "username/update/empty")
    return "notifications/username/update/empty"

  if (message === "username/update/bad-formed")
    return "notifications/username/update/bad-formed"

  if (message === "username/update/not-logged")
    return "notifications/username/update/not-logged"

  if (message === "username/updated") return "notifications/username/updated"

  return message
}

const Notification: React.FC<{
  onRemove: () => any
  type: NotificationEntity["type"]
  message: NotificationEntity["message"]
}> = (props) => (
  <Transition
    appear={true}
    show
    as={"div"}
    className={
      "w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5"
    }
    enter="transform ease-out duration-300 transition"
    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
    leave="transition ease-in duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="">
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {props.type === "success" && (
              <CheckCircleIcon
                className="w-6 h-6 text-green-400"
                aria-hidden="true"
              />
            )}
            {props.type === "info" && (
              <InformationCircleIcon
                className="w-6 h-6 text-blue-400"
                aria-hidden="true"
              />
            )}
            {props.type === "error" && (
              <XCircleIcon
                className="w-6 h-6 text-red-400"
                aria-hidden="true"
              />
            )}
            {props.type === "warning" && (
              <ExclamationTriangleIcon
                className="w-6 h-6 text-yellow-400"
                aria-hidden="true"
              />
            )}
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-slate-900">
              {props.type === "success" && (
                <FormattedMessage id="notifications/success" />
              )}
              {props.type === "error" && (
                <FormattedMessage id="notifications/error" />
              )}
              {props.type === "warning" && (
                <FormattedMessage id="notifications/warn" />
              )}
              {props.type === "info" && (
                <FormattedMessage id="notifications/info" />
              )}
            </p>
            <p className="mt-1 text-sm text-slate-500">
              <FormattedMessage id={getMessage(props.message)} />
            </p>
          </div>
          <div className="flex flex-shrink-0 ml-4">
            <button
              className="inline-flex text-slate-400 bg-white rounded-md hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={props.onRemove}
            >
              <XMarkIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
)

export type NotificationsProps = {
  notifications: Array<NotificationEntity>
  onRemove?: Function
  test?: Function
}

export const Wrapper: React.FC<NotificationsProps> = ({
  notifications,
  onRemove = () => false,
}) => {
  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
        {notifications.map(({ type, message, id }) => (
          <Notification
            key={id}
            type={type}
            message={message}
            onRemove={() => onRemove(id)}
          />
        ))}
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Notifications = connector(Container)
