import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import {
  DAILY_SMALL_CHEST_ID,
  HEARTS_ONE_DAY_POWER_UP,
  marketplaceCoinsItems,
  marketplaceItems,
  SUBSCRIPTION_ITEM_ID,
  SUBSCRIPTION_PRODUCT_ID,
} from "../../database/marketplace-items"
import { actions } from "../actions"
import {
  closeSubscriptionModal,
  openSubscriptionSuccessModal,
} from "../../utils/drawerUtils"

export const setFetchingMarketplace = (
  payload: types.setFetchingMarketplaceAction["payload"]
): types.MarketplaceActionTypes => ({
  type: types.setFetchingMarketplace,
  payload,
})

export const refresh = (): types.MarketplaceActionTypes => ({
  type: types.refresh,
})

export const storeMarketplace = (
  payload: types.storeMarketplaceAction["payload"]
): types.MarketplaceActionTypes => ({
  type: types.storeMarketplace,
  payload,
})

export const setFetchingItem = (
  payload: types.setFetchingItemAction["payload"]
): types.MarketplaceActionTypes => ({
  type: types.setFetchingItem,
  payload,
})

export const buyItem = (
  payload: types.buyItemAction["payload"]
): types.MarketplaceActionTypes => ({
  type: types.buyItem,
  payload,
})

export const $fetch =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    dispatcher(setFetchingMarketplace({ fetching: true }))

    const response = auth.user?.id
      ? await di.MarketplaceRepository.getAll({
          userId: auth.user.id,
        })
      : []

    dispatcher(
      storeMarketplace({
        items: response,
      })
    )

    dispatcher(setFetchingMarketplace({ fetching: false }))
  }

export const $buy =
  (params: { itemId: string }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, auth, marketplace, coins } = getState()

    if (marketplace.fetching) return false
    if (marketplace.itemFetching) return false
    if (!auth.user?.id) {
      dispatcher(
        actions.notifications.create({
          type: "warning",
          message: "notifications/marketplace/not-authenticated",
        })
      )
      return false
    }

    const itemToBuy = marketplaceItems.find(
      ({ itemId }) => params.itemId === itemId
    )

    console.log(itemToBuy)

    if (coins.amount < itemToBuy.price) {
      dispatcher(
        actions.notifications.create({
          message: "notifications/marketplace/insuficiant-found",
          type: "info",
        })
      )

      return
    }

    dispatcher(setFetchingItem({ itemId: params.itemId }))

    const item = {
      itemId: params.itemId,
      userId: auth.user.id,
      bougthAt: new Date(),
    }

    await di.MarketplaceRepository.store({
      userId: auth.user.id,
      item,
    })

    dispatcher(actions.coins.$spend(itemToBuy.price))
    dispatcher(buyItem({ item }))

    di.AnalyticsService.send({
      category: "marketplace",
      action: "buy",
      data: {
        item: params.itemId,
        type: itemToBuy.type,
      },
    })

    if (item.itemId === DAILY_SMALL_CHEST_ID) {
      await dispatcher(actions.chests.$storeDailyChest())
    } else {
    }

    if (item.itemId === HEARTS_ONE_DAY_POWER_UP) {
      dispatcher(actions.play.$removeAllGamesFromLocalStorage())
    }

    dispatcher(setFetchingItem({ itemId: null }))
  }

// export const $openPayment =
//   (params: { productId: string }): ThunkAction<void, RootState, any, any> =>
//   async (dispatcher, getState) => {
//     const { auth, di } = getState()

//     if (!auth.user?.id) {
//       dispatcher(
//         actions.notifications.create({
//           type: "warning",
//           message: "notifications/marketplace/not-authenticated",
//         })
//       )
//       return false
//     }

//     di.AnalyticsService.send({
//       category: "payment",
//       action: "open",
//     })

//     const product = marketplaceCoinsItems.find(
//       ({ id }) => id === params.productId
//     )

//     dispatcher(setFetchingItem({ itemId: params.productId }))

//     const response = await di.PaymentService.openDialog({
//       email: auth.user?.email as string,
//       productId: product.id,
//     })

//     dispatcher(setFetchingItem({ itemId: null }))

//     if (response.status === "closed") {
//       di.AnalyticsService.send({
//         category: "payment",
//         action: "close",
//       })
//     } else {
//       dispatcher(actions.coins.$addCoins(product.amount))

//       di.AnalyticsService.send({
//         category: "payment",
//         action: "success",
//         data: {
//           amount: product.amount,
//           type: "emeralds",
//         },
//       })
//     }
//   }

export const $subscribe =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { auth, di } = getState()

    if (!auth.user?.id) {
      dispatcher(
        actions.notifications.create({
          type: "warning",
          message: "notifications/marketplace/not-authenticated",
        })
      )
      return false
    }

    di.AnalyticsService.send({
      category: "payment",
      action: "open",
    })

    await di.PaymentService.openSubscriptionDialog({
      email: auth.user?.email as string,
    })
  }
