export const Table = ({ children }) => {
  return (
    <div className="w-full my-8 overflow-x-auto">
      <table className="min-w-full divide-y divide-slate-200 border border-slate-200 rounded-lg">
        {children}
      </table>
    </div>
  )
}

export const Th = ({ children }) => {
  return (
    <th className="px-6 py-3 text-left text-sm font-semibold text-slate-900 bg-slate-50">
      {children}
    </th>
  )
}

export const Tr = ({ children }) => {
  return <tr className="hover:bg-slate-50 transition-colors">{children}</tr>
}

export const Td = ({ children }) => {
  return (
    <td className="px-6 py-4 text-sm text-slate-600 border-t border-slate-200">
      {children}
    </td>
  )
}
