import stats from "../urls/stats.json"
import leaderboard from "../urls/leaderboard.json"
import lessons from "../urls/lessons.json"
import levels from "../urls/levels.json"
import daily from "../urls/daily.json"
import share from "../urls/share.json"
import blog from "../urls/blog.json"
import signin from "../urls/signin.json"
import print from "../urls/print.json"

export const normalizeUrl = (lang, url) => {
  if (lang === "en") return `${url}`
  return `/${lang}${url}`
}

export const getPremiumUrl = (lang) => {
  if (lang === "en") return `/pricing/`

  return `/${lang}/pricing/`
}

export const getTermsOfServiceUrl = (lang) =>
  normalizeUrl(lang, "/terms-of-service/")

export const getRefundPolicyUrl = (lang) =>
  normalizeUrl(lang, "/refund-policy/")

export const getSigninUrl = (lang) => {
  if (lang === "en") return `/${signin[lang].signin}/`

  return `/${lang}/${signin[lang].signin}/`
}

export const getSigninLinkValidationUrl = (lang) => {
  return `${getSigninUrl(lang)}${signin[lang].validation}/`
}

export const getLessonsUrl = (lang) => {
  if (lang === "en") return `/${leaderboard[lang]}/`

  return `/${lang}/${leaderboard[lang]}/`
}

export const getLeaderboardUrl = (lang) => {
  if (lang === "en") return `/${leaderboard[lang]}/`

  return `/${lang}/${leaderboard[lang]}/`
}

export const getBlogUrl = (lang) => {
  if (lang === "en") return `/${blog[lang]}/`

  return `/${lang}/${blog[lang]}/`
}

export const getBlogArticleUrl = (lang, url) => {
  return normalizeUrl(lang, `/blog${url}`)
}

export const navigateToStats = (lang) => {
  if (lang === "en") return `/${stats[lang]}/`

  return `/${lang}/${stats[lang]}/`
}

export const getShareUrl = (lang) => {
  if (lang === "en") return `/${share[lang]}/`

  return `/${lang}/${share[lang]}/`
}

export const oldNavigateToLessons = (lang) => {
  return lang === "en"
    ? `/${lessons[lang].index}/`
    : `/${lang}/${lessons[lang].index}/`
}

export const navigateToLessons = (lang) => {
  return lang === "en" ? `/learn/` : `/${lang}/learn/`
}

export const getLessonUrl = ({ lang, level, url }) => {
  return `${navigateToLessons(lang)}${url}/`
}

export const getCourseUrl = ({ lang, level }) => {
  return lang === "en"
    ? `/${lessons[lang].index}/${levels[lang][level]}/`
    : `/${lang}/${lessons[lang].index}/${levels[lang][level]}/`
}

export const getCoursesUrl = ({ lang }) => {
  return lang === "en"
    ? `/${lessons[lang].index}/`
    : `/${lang}/${lessons[lang].index}/`
}

export const navigateToHome = (lang) => {
  return lang === "en" ? "/" : `/${lang}/`
}

export const getLevelUrl = (lang, level) => {
  const url =
    lang === "en"
      ? `/${levels[lang][level]}/`
      : `/${lang}/${levels[lang][level]}/`

  return url
}

export const getDailyUrl = (lang, level) => {
  const url =
    lang === "en"
      ? `/${daily[lang]}/${levels[lang][level]}/`
      : `/${lang}/${daily[lang]}/${levels[lang][level]}/`

  return url
}

export const getPrintUrl = (lang, level) => {
  return getLevelUrl(lang, level) + print[lang] + "/"
}

export const getMarkeplaceUrl = (lang) => {
  if (lang === "en") return `/marketplace/`

  return `/${lang}/marketplace/`
}

export const getProfileUrl = (lang) => {
  if (lang === "en") return `/profile/`

  return `/${lang}/profile/`
}

export const getAchievementsUrl = (lang) => {
  if (lang === "en") return `/achievements/`

  return `/${lang}/achievements/`
}
