import { MarketingEntity } from "./MarketingEntity"

export enum ArticleTypes {
  QUOTE = "article/quote",
  RICH_TEXT = "article/rich_text",
  TITLE = "article/title",
  IMAGE = "article/image",
  ARTICLE = "article/article",
  TOC = "article/toc",
}

export type ArticleTOC = {
  id: string
  type: ArticleTypes.TOC
  title: {
    component: string
    value: string
  }
  items: Array<{ label: string; to: string; depth: number }>
}

export type ArticleQuote = {
  id: string
  type: ArticleTypes.QUOTE
  text: string
  author: string
}

export type ArticleRichText = {
  id: string
  type: ArticleTypes.RICH_TEXT
  content: string
}

export type ArticleToArticle = {
  id: string
  type: ArticleTypes.ARTICLE
  article: ArticleEntity
}

export type ArticleTitle = {
  type: ArticleTypes.TITLE
  value: string
  id: string
  component: string
}

export type ArticleImage = {
  id: string
  type: ArticleTypes.IMAGE
  src?: string
  alt: string
  legend: string
}

export type ArticleEntity = {
  id: string
  title: string
  lang: string
  description: string
  published_at: Date
  updated_at: Date
  canonical: string | null
  url: string
  articles?: ArticleEntity[]
  meta: {
    title: string
    description: string
    indexable: boolean
  }
  illustration: {
    alt: string
    src: any
  }
  preview: {
    alt: string
    src: any
  }
  content: Array<
    | ArticleQuote
    | ArticleRichText
    | ArticleTitle
    | ArticleImage
    | ArticleTOC
    | ArticleToArticle
    | MarketingEntity
  >
  author?: {
    name: string
    id: string
  }
  category?: {
    id: string
    url: string
    name: string
  }
}

export type LessonEntity = ArticleEntity & {
  breadcrumb_title: string
}
