import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore"
import { setDoc } from "firebase/firestore"
import {
  LeaderboardEntity,
  MetaLeaderboardEntity,
} from "../entities/LeaderboardEntity"
import { UserEntity } from "../entities/UserEntity"
import { ILeaderboardRepository } from "../interfaces/ILeaderboardRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseLeaderboardRepository
  extends FirebaseUtils
  implements ILeaderboardRepository
{
  private collection = "leaderboard"

  constructor(private firebase: Firebase) {
    super()
  }

  async storeUserOnLeague(user: LeaderboardEntity): Promise<LeaderboardEntity> {
    const db = this.firebase.database()

    await setDoc(doc(db, this.collection, user.userId), user)

    return user
  }

  async getMeta(): Promise<MetaLeaderboardEntity> {
    const db = this.firebase.database()
    const response = await getDoc(doc(db, "meta", "leaderboard"))
    const meta = response.data()
    return {
      ...meta,
      end: meta.end.toDate(),
      start: meta.start.toDate(),
    } as MetaLeaderboardEntity
  }

  async getUserLeague(params: {
    userId: string
  }): Promise<LeaderboardEntity[]> {
    const db = this.firebase.database()

    const user = await getDoc(doc(db, this.collection, params.userId))

    if (!user.exists()) return []

    const data = user.data() as LeaderboardEntity

    const response = await getDocs(
      query(
        collection(db, this.collection),
        where("league", "==", data.league),
        where("bracket", "==", data.bracket)
      )
    )

    return this.mapQuerySnapshot<LeaderboardEntity>(response)
  }

  async storePoints(params: {
    userId: string
    points: number
    avatar: string
    username: string
  }): Promise<LeaderboardEntity> {
    const db = this.firebase.database()
    const document = doc(db, this.collection, params.userId)
    const getDocument = await getDoc(document)
    const data = getDocument.data() as LeaderboardEntity

    if (!data) {
      return data
    }

    const entity = {
      ...data,
      ...params,
      points: params.points + data?.points || 0,
    }

    await setDoc(document, entity, { merge: true })

    return entity
  }
}
