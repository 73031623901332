import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore"
import { PointsEntity } from "../entities/PointsEntity"
import {
  IPointsRepository,
  PointsUpdateProfileReturnType,
} from "../interfaces/IPointsRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebasePointsRepository
  extends FirebaseUtils
  implements IPointsRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "points"

  async fetch(params: { userId: string }): Promise<{ points: number }> {
    const db = this.firebase.database()

    const c = await getDoc(doc(db, this.collection, params.userId))

    if (!c.exists()) return { points: 0 }

    return {
      points: c.data()?.points || 0,
    }
  }

  async getUser(params: { userId: string }): Promise<PointsEntity | null> {
    const db = this.firebase.database()

    const c = await getDoc(doc(db, this.collection, params.userId))

    if (!c.exists()) return null

    return c.data() as PointsEntity
  }

  async fetchAll(params: { limit: number }): Promise<PointsEntity[]> {
    const db = this.firebase.database()

    const c = query(
      collection(db, this.collection),
      orderBy("points", "desc"),
      limit(params.limit)
    )

    const response = await getDocs(c)

    const data = this.mapQuerySnapshot<PointsEntity>(response)

    return data
  }

  async store(params: PointsEntity): Promise<PointsEntity> {
    const db = this.firebase.database()
    const document = doc(db, this.collection, params.userId)
    const getDocument = await getDoc(document)
    const data = getDocument.data() as PointsEntity

    console.log(params)

    await setDoc(
      document,
      {
        ...params,
        points: (data.points || 0) + (params.points || 0),
      },
      { merge: true }
    )

    return params
  }

  async updateProfile(params: {
    username?: string
    avatar?: string
  }): Promise<PointsUpdateProfileReturnType> {
    const db = this.firebase.database()
    const auth = this.firebase.auth()

    const user = auth.currentUser

    if (!user) return { success: false, error: "not-logged" }

    const document = doc(db, this.collection, user?.uid)

    await setDoc(document, params, { merge: true })

    return { success: true }
  }

  async sync(params: {
    userId: string
    avatar: string
    username: string
  }): Promise<{ succeed: boolean }> {
    try {
      const points = Number(window.localStorage.getItem("points")) || 0

      if (!points) return { succeed: true }

      const exists = await this.fetch(params)

      if (exists.points) return { succeed: true }

      await this.store({ ...params, points })

      window.localStorage.removeItem("points")

      return { succeed: true }
    } catch (e) {
      console.error(e)
      return { succeed: false }
    }
  }
}
