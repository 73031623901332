import { doc, getDoc, setDoc } from "@firebase/firestore"
import { AchievementEntity } from "../entities/AchievementEntity"
import { IAchievementsRepository } from "../interfaces/IAchievementsRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseAchievementsRepository
  extends FirebaseUtils
  implements IAchievementsRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "achievements"

  async fetchAll(params: { userId: string }): Promise<AchievementEntity[]> {
    const db = this.firebase.database()

    const response = await getDoc(doc(db, this.collection, params.userId))

    if (!response.exists()) return []

    const data = response.data() as { [x: string]: AchievementEntity }

    return Object.entries(data).map(([id, achievement]) => ({
      ...achievement,
      id,
    }))
  }

  async storeMany(params: {
    userId: string
    achievements: { [x: string]: AchievementEntity }
  }): Promise<{ success: boolean }> {
    const db = this.firebase.database()
    const document = doc(db, this.collection, params.userId)

    try {
      await setDoc(document, params.achievements, { merge: true })
    } catch (e) {}

    return { success: true }
  }
}
